.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);}

    .modal-main {
      position:absolute;
      background: white;
      width: 80%;
      height: auto;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      z-index: 2;
    }


  
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  